import axios from 'axios';
import $ from 'jquery'
let _APIURL = '';
let _APIConfig = '';
switch(window.location.hostname){
    case "localhost": _APIURL = 'http://localhost:5254'; break;
    case "horapa.order.in.th": _APIURL = 'https://horapa.order.in.th/API'; break;
    default : _APIURL = window.location.protocol+'//'+window.location.hostname+'/API'; break;
}

if (sessionStorage.getItem("Authorization") != undefined && sessionStorage.getItem("Authorization").length != 0) {
    _APIConfig = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem("Authorization")
    };
    $.ajaxSetup({
        headers: { 'Authorization': sessionStorage.getItem("Authorization") },
        beforeSend: function(xhr, options) {
            if( options.url.indexOf('http') !== 0 ) {
                options.url = _APIURL + options.url;
            }
        }
    });

} else {
    _APIConfig = {
        'Content-Type': 'application/json'
    };
    $.ajaxSetup({
        headers: { }
    });
}

export default axios.create({
    baseURL: _APIURL,
    headers: _APIConfig
});
