import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Table,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import { DateFormat, DateThai,DateFormatJSON,MobileFormat, NumberFormat,RootURL } from '../../Utils/Function';
import {OrderStatus,OrderStatusLabel,AppointmentType} from '../../Utils/Status';
import {FileUpload} from '../../Components';
import OrderCardStatusInfo from './OrderCardStatusInfo';
import OrderCardEdit from './OrderCardEdit';
import parse from 'html-react-parser'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderCardInfo = forwardRef((props,ref) =>{
    const _OrderCardStatusInfo = useRef();
    const _OrderCardEdit = useRef();
    const [ModalState,setModalState] = useState(false);
    const [OrderDetail,setOrderDetail] = useState(undefined);
    const [PackgeProfile,setPackgeProfile] = useState(undefined);
    const [PackageList,setPackageList] = useState([]);
    const [PromotionList,setPromotionList] = useState([]);
    const [OrderStatusList,setOrderStatusList] = useState([]);
    const [DepositValue,setDepositValue] = useState();
    const [FileLsit,setFileList] = useState([]);
    const [_Remark,setRemark] = useState('');
    const [_AppointmentID,setAppointmentID] = useState();

    const [_EditDepositDate,setEditDepositDate] = useState(false);
    const [_DepositDate,setDepositDate] = useState(new Date());

    const[_EditDepositAmount,setEditDepositAmount] = useState(false);
    const [_DepositAmount,setDepositAmount] = useState(0);

    const[_EditDepositAppointment,setEditDepositAppointment] = useState(false);
    const [_DepositAppointment,setDepositAppointment] = useState(new Date());

    const [_AppointmentList,setAppointmentList] = useState([]);
    useEffect(() => {
        (async () => {
            
        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(OrderID){
            setAppointmentID(undefined);
            _GetOrderDetail(OrderID);
        },
        ShowAppointment(OrderID,AppointmentID){
            _GetOrderDetail(OrderID);
            setAppointmentID(AppointmentID);
        }
      }));
      const _GetOrderDetail = async(OrderID) =>{
        setOrderDetail(undefined);
        setPackgeProfile(undefined);
        setPackageList([]);
        setPromotionList([]);
        setRemark('');
        setEditDepositDate(false);
        setEditDepositAmount(false);
        setEditDepositAppointment(false);
        setDepositAmount(0);
        API({method : "get",url:"/Order/Detail/"+OrderID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            setOrderDetail(res.data.Response.Detail);
            setPackgeProfile(res.data.Response.PackageDetail);
            setPackageList(res.data.Response.PackageList);
            setPromotionList(res.data.Response.PromotionList);
            setRemark(res.data.Response.Detail.Remark);
            if(res.data.Response.Detail.DepositAmountActual != null) setDepositAmount(res.data.Response.Detail.DepositAmountActual); else setDepositAmount(0);
            if(res.data.Response.Detail.DepositAppointment != null) setDepositAppointment(res.data.Response.Detail.DepositAppointment); else setDepositAppointment(new Date());
            //setPackageGroup(res.data.Response.PackageGroup);
            _GetOrdeStatus(OrderID);
            _GetOrderFile(OrderID);
            _GetOrdeAppointment(OrderID);
            setModalState(true);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });
      }
      const _GetOrdeStatus = async(OrderID) =>{
        setOrderStatusList([]);
        API({method : "get",url:"/Order/Status/"+OrderID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setOrderStatusList(res.data.Response);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });
      }
      const _GetOrderFile = async(OrderID) =>{
        setFileList([]);
        API({method : "get",url:"/Order/File/"+OrderID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setFileList(res.data.Response);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });
      }

      const _GetOrdeAppointment = async(OrderID) =>{
        setOrderStatusList([]);
        API({method : "get",url:"/Appointment/Order/"+OrderID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setAppointmentList(res.data.Response);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });
      }
    const _AccountType = (AccountType)=>{
        switch(AccountType){
            case 0:return '-';
            case 1:return 'บริษัท';
            case 2:return 'ร้าน';
            default:return '';
        }
    }
    const _Reason = (ReasonID) =>{
        if(ReasonID === 0) return 'ไม่ระบุ';
    }
    const _CloseModel = ()=>{
        setModalState(false);
        if(props.callback != undefined){
            try{
                props.callback();
            }catch(e){}
        }
    }
    const _UpdateOrderCardStatusInfo = (val)=>{
        if(val) setModalState(false); else setModalState(true);
        if(!val) _GetOrderDetail(OrderDetail.OrderId);
    }
    const _Deposit = async()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการชำระมัดจำ หรือไม่ ?',
            text : (OrderDetail != undefined ? OrderDetail.CustomerName + ' ['+MobileFormat(OrderDetail.CustomerMobile)+'] :: '+DateThai(OrderDetail.EventDate):"1"),
            input: 'text',
            showCancelButton : true,
            confirmButtonText: 'ชำระมัดจำ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก',
            preConfirm: () => {
                //setDepositValue(Swal.getInput()?.value || '')
            }
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            if(isNaN(Number(r.value))) {
                Swal.fire({
                    icon: 'error',
                    title: 'รูปแบบข้อมูลยอดเงินไม่ถูกต้อง'
                });
                return;
            }
            if(Number(r.value) <=0){
                Swal.fire({
                    icon: 'error',
                    title: 'โปรดระบุยอดเงินมัดจำ'
                });
                return;
            }

            API({method : 'post',url:'/Order/Deposit/'+OrderDetail.OrderId,data: {Amount:Number(r.value) },headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                _GetOrderDetail(OrderDetail.OrderId);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    const _Complete = async()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการจบงาน หรือไม่ ?',
            text : (OrderDetail != undefined ? OrderDetail.CustomerName + ' ['+MobileFormat(OrderDetail.CustomerMobile)+'] :: '+DateThai(OrderDetail.EventDate):"1"),
            input: 'text',
            showCancelButton : true,
            confirmButtonText: 'เสร็จสิ้น',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก',
            preConfirm: () => {
                //setDepositValue(Swal.getInput()?.value || '')
            }
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            if(isNaN(Number(r.value))) {
                Swal.fire({
                    icon: 'error',
                    title: 'รูปแบบข้อมูลยอดเงินไม่ถูกต้อง'
                });
                return;
            }
            if(Number(r.value) <=0){
                Swal.fire({
                    icon: 'error',
                    title: 'โปรดระบุยอดเงินมัดจำ'
                });
                return;
            }

            API({method : 'post',url:'/Order/Complete/'+OrderDetail.OrderId,data: {Amount:Number(r.value) },headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                _GetOrderDetail(OrderDetail.OrderId);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    const _Save = async()=>{
        const _reqObj = {
            Remark : _Remark,
        };
        API({ method: "post", url: "/Order/Detail/"+(OrderDetail != undefined ? OrderDetail.OrderId : ''), data: _reqObj, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message
            });
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });;
    }
    const _Edit = async()=>{
        _OrderCardEdit.current.Show(OrderDetail);
    }
    const _EditCallBack = (val)=>{
        if(val) setModalState(false); else setModalState(true);
        if(!val) _GetOrderDetail(OrderDetail.OrderId);
    }
    const _DeleteFile = async(FileObj)=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการลบไฟล์ หรือไม่ ?',
            text : FileObj.FileName,
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/File/"+FileObj.OrderFileID}).then(res=>{
                if (res.status !== 200) {
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.data.Message,
                    });
                    return;
                }
    
                if (!res.data.Status) {
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message,
                    });
                    return;
                }
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                _GetOrderFile((OrderDetail != undefined ? OrderDetail.OrderId:''));
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                })
            });
        });
    }
    const _EditDepositDateClick = ()=>{
        if(sessionStorage.getItem('Role') != 99) return;
        if(OrderDetail != undefined && OrderDetail.DepositDate === null) return;
        setDepositDate(OrderDetail.DepositDate);
        setEditDepositDate(true);
    }
    const _EditDepositDateUpdate = async(val)=>{

        setEditDepositDate(false);

        API({ method: "post", url: "/Order/Deposit/Date/"+(OrderDetail != undefined ? OrderDetail.OrderId : ''), data: {DepositDate:DateFormatJSON(val)}, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message
            });
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });;
        if(OrderDetail != undefined){
            OrderDetail.DepositDate = val;
        }


        Swal.fire({
            icon: 'warning',
            title: 'ต้องการแก้ไขวันชำระ หรือไม่ ?',
            showCancelButton : true,
            confirmButtonText: 'แก้ไข',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                setDepositDate(OrderDetail.DepositDate);
                return;
            }
            API({ method: "post", url: "/Order/Deposit/Date/"+(OrderDetail != undefined ? OrderDetail.OrderId : ''), data: {DepositDate:DateFormatJSON(val)}, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    setDepositDate(OrderDetail.DepositDate);
                    return;
                }

                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                if(OrderDetail != undefined){
                    let _Obj = OrderDetail;
                    _Obj.DepositDate = val;
                    setOrderDetail(_Obj);
                }
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
                setDepositDate(OrderDetail.DepositDate);
            });;
        }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
                setDepositDate(OrderDetail.DepositDate);
        });
    }

    const _EditDepositAmountClick = ()=>{
        if(sessionStorage.getItem('Role') != 99) return;
        if(OrderDetail != undefined && OrderDetail.DepositAmountActual === null) return;
        setEditDepositAmount(true);
    }
    const _EditDepositAmountUpdate = async()=>{
        setEditDepositAmount(false);

        API({ method: "post", url: "/Order/Deposit/Amount/"+(OrderDetail != undefined ? OrderDetail.OrderId : ''), data: {Amount:Number(_DepositAmount)}, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message
            });
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });;
        if(OrderDetail != undefined){
            let _Obj = OrderDetail;
            _Obj.DepositDate = _DepositAmount;
        }
    }

    const _EditDepositAppointmentClick = ()=>{
        if(sessionStorage.getItem('Role') != 99) return;
        if(OrderDetail != undefined && OrderDetail.DepositAppointment === null) return;
        setEditDepositAppointment(true);
    }
    const _EditDepositAppointmentUpdate = async(val)=>{
        setEditDepositAppointment(false);
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการแก้ไขวันนัดชำระ หรือไม่ ?',
            text : DateFormat(val),
            showCancelButton : true,
            confirmButtonText: 'แก้ไข',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                setDepositAppointment(OrderDetail.DepositAppointment);
                return;
            }
            API({ method: "post", url: "/Order/Deposit/Appointment/"+(OrderDetail != undefined ? OrderDetail.OrderId : ''), data: {AppointmentDate:DateFormatJSON(val)}, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    setDepositAppointment(OrderDetail.DepositAppointment);

                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                if(OrderDetail != undefined){
                    let _Obj = OrderDetail;
                    _Obj.DepositAppointment = val;
                    setOrderDetail(_Obj);
                }
                setDepositAppointment(val);
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
                setDepositAppointment(OrderDetail.DepositAppointment);

            });
        }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
                setDepositAppointment(OrderDetail.DepositAppointment);
        });
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{(OrderDetail != undefined ? OrderDetail.CustomerName + ' ['+MobileFormat(OrderDetail.CustomerMobile)+'] :: '+DateThai(OrderDetail.EventDate):"1")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{_CloseModel()}}></button>
            </div>

            <div className='modal-body'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <h5>รายละเอียด</h5>
                                    </div>
                                    <div className='col-lg-4 text-end'>
                                        <div className='btn-group btn-group-sm'>
                                            <button className='btn btn-warning text-white' disabled={(OrderDetail != undefined && OrderDetail.DepositAmountActual  === null ?false:true)} type='button' onClick={()=>{_Deposit();}}>ชำระมัดจำ</button>
                                            <button className='btn btn-secondary text-white' disabled={(OrderDetail != undefined && OrderDetail.Status === 5?false:true)} type='button' onClick={()=>{_Complete();}}>จบงาน</button>
                                            <a href={(OrderDetail != undefined ? OrderDetail.CalendarUrl:'#')} className='btn btn-info text-white' target='_blank'>ปฎิทิน</a>
                                            <a href={(OrderDetail != undefined ? OrderDetail.LineChatUrl:'#')} className='btn btn-success text-white' target='_blank'>LINE</a>
                                            <button className='btn btn-primary text-white' disabled={(OrderDetail === undefined ? true:false)} onClick={()=>{_Edit();}}>แก้ไข</button>
                                            <button className='btn btn-danger text-white' disabled={(OrderDetail === undefined ? true:false)} onClick={()=>{_Save();}}>บันทึก</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='card-body'>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ชื่อลูกค้า :</span>
                                    </div>
                                    <div className='col-3'>
                                        <span className=''>{(OrderDetail != undefined?OrderDetail.CustomerName:"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>เบอร์โทร :</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className=''>{(OrderDetail != undefined?MobileFormat(OrderDetail.CustomerMobile):"")}</span>
                                    </div>
                                    <div className='col-3 text-center'>
                                        <h5><span className=''>{parse((OrderDetail != undefined?OrderStatusLabel(OrderDetail.Status):""))}</span></h5>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>วันที่เสนอราคา :</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className=''>{(OrderDetail != undefined?DateFormat(OrderDetail.QuotationDate):"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>วันที่จัดงาน :</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className=''>{(OrderDetail != undefined?DateFormat(OrderDetail.EventDate):"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>จำนวนหัว :</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className=''>{(OrderDetail != undefined?OrderDetail.PeopleNum:"")}</span>
                                    </div>
                                    
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ผลิตภัณฑ์ :</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className=''>{(PackgeProfile != undefined?PackgeProfile.Group.PackageGroupName:"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>แพ็คเก็จ :</span>
                                    </div>
                                    <div className='col-6'>
                                        <span className=''>{(PackgeProfile != undefined?PackgeProfile.Package.PackageName:"")}</span>
                                    </div>
                                    
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ประเภท :</span>
                                    </div>
                                    <div className='col-2'>
                                        <span className=''>{(OrderDetail != undefined?_AccountType(OrderDetail.AccountType):"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>นัดชำระวันที่ :</span>
                                    </div>
                                    <div className='col-2'>
                                    {(_EditDepositAppointment?<DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={_DepositAppointment} onChange={(date)=>{setDepositAppointment(date);_EditDepositAppointmentUpdate(date);}} selectsStart startDate={_DepositAppointment}/>:<span className='' onClick={()=>{_EditDepositAppointmentClick();}}>{(_DepositAppointment != null ?DateFormat(_DepositAppointment):"-")}</span>)}
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>มัดจำวันที่ :</span>
                                    </div>
                                    <div className='col-2'>
                                    {(_EditDepositDate?<DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy HH:mm'} selected={_DepositDate} onChange={(date)=>{setDepositDate(date);_EditDepositDateUpdate(date);}} selectsStart startDate={_DepositDate}/>:<span className='' onClick={()=>{_EditDepositDateClick();}}>{(OrderDetail != undefined && OrderDetail.Status ===5 && OrderDetail.DepositDate != null ?DateFormat(OrderDetail.DepositDate,true):"-")}</span>)}
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ยอดเสนอ :</span>
                                    </div>
                                    <div className='col-1'>
                                        <span className=''>{(OrderDetail != undefined?NumberFormat(OrderDetail.QuotationAmount):"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ยอดมัดจำ :</span>
                                    </div>
                                    <div className='col-1'>
                                        <span className=''>{(OrderDetail != undefined?NumberFormat(OrderDetail.DepositAmount):"")}</span>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>รับมัดจำ :</span>
                                    </div>
                                    <div className='col-1'>
                                        {(_EditDepositAmount? <input type='text' className='form-control' defaultValue={_DepositAmount} onKeyDown={(e)=>{if(e.keyCode === 8) return; if(e.keyCode === 13){ _EditDepositAmountUpdate();return;}if(isNaN(Number(e.key))) {e.preventDefault(); return;} }} onChange={(e)=>{ setDepositAmount(e.target.value);}} /> :<span className='' onClick={()=>{_EditDepositAmountClick();}}>{(OrderDetail != undefined?NumberFormat(_DepositAmount):"")}</span>)}
                                        
                                    </div>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>ยอดจบงาน :</span>
                                    </div>
                                    <div className='col-1'>
                                        <span className=''>{(OrderDetail != undefined?NumberFormat(OrderDetail.CompleteAmount):"")}</span>
                                    </div>
                                </div>
                                <div className='row mt-4 mb-2'>
                                    <div className='col-lg-8 table-responsive'>
                                        <table className='table table-hover'>
                                            <thead className='table-success'>
                                                    <tr>
                                                        <th className='text-center'>ผลิตภัณฑ์</th>
                                                        <th className='text-center'>แพ็คเก็จ</th>
                                                        <th className='text-center'>รายละเอียด</th>
                                                        <th className='text-center'>ราคา</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {PackageList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-center'>{v.Group.PackageGroupName}</td>
                                                        <td className='text-left'>{v.Package.PackageName}</td>
                                                        <td className='text-left'>{v.Package.Description}</td>
                                                        <td className='text-end'>{NumberFormat(v.Package.Price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-lg-4 table-responsive'>
                                        <table className='table table-hover'>
                                            <thead className='table-info'>
                                                    <tr>
                                                    <th className='text-center'>โปรโมชั่น Code</th>
                                                        <th className='text-center'>โปรโมชั่น</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {PromotionList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-center'>{v.PromotionCode}</td>
                                                        <td className='text-left'>{v.PromotionName}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-2 text-end'>
                                        <span className='fw-bold'>หมายเหตุ :</span>
                                    </div>
                                    <div className='col-6'><textarea className='form-control' defaultValue={_Remark} onChange={(e)=>{setRemark(e.target.value);}} /></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row'>
                                    <div className='col-lg-8'><h5 className='mt-1'>สถานะ</h5></div>
                                    <div className='col-lg-4 text-end'>
                                        <div className='btn-group btn-group-sm'>
                                            <button className='btn btn-success' onClick={()=>{_OrderCardStatusInfo.current?.Show(OrderDetail);}}>เพิ่ม</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>วันที่</th>
                                                    <th className='text-center'>ครั้งที่</th>
                                                    <th className='text-center'>สถานะ</th>
                                                    <th className='text-center'>เหตุผล</th>
                                                    <th className='text-center'>หมายเหตุ</th>
                                                    <th className='text-center'>พนักงาน</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {OrderStatusList.map((v,i)=>(
                                                <tr key={i}>
                                                    <td className='text-center'>{DateFormat(v.CreateDate,true)}</td>
                                                    <td className='text-center'>{v.StatusStage}</td>
                                                    <td className='text-center'>{OrderStatus(v.Status)}</td>
                                                    <td className='text-center'>{v.Reason}</td>
                                                    <td className='text-left'>{v.Remark}</td>
                                                    <td className='text-center'>{v.Name}</td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row'>
                                    <div className='col-lg-8'><h5 className='mt-1'>นัดหมาย</h5></div>
                                    <div className='col-lg-4 text-end'>
                                        <div className='btn-group btn-group-sm'>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>วันที่นัด</th>
                                                    <th className='text-center'>ประเภทนัด</th>
                                                    <th className='text-center'>Topic</th>
                                                    <th className='text-center'>หมายเหตุ</th>
                                                    <th className='text-center'>วันที่สร้างนัด</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {_AppointmentList.map((v,i)=>(
                                                <tr key={i}>
                                                    <td className='text-center'>{DateFormat(v.AppointmentTime,true)}</td>
                                                    <td className='text-center'>{AppointmentType(v.AppointmentType)}</td>
                                                    <td className='text-center'>{v.Topic}</td>
                                                    <td className='text-left'>{v.Remark}</td>
                                                    <td className='text-center'>{DateFormat(v.CreateDate,true)}</td>

                                                    <td></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row'>
                                    <div className='col-lg-8'><h5 className='mt-1'>ไฟล์เอกสาร</h5></div>
                                    <div className='col-lg-4 text-end'>
                                        <FileUpload OrderID={(OrderDetail != undefined ? OrderDetail.OrderId:'')} callback={(e)=>{ _GetOrderFile((OrderDetail != undefined ? OrderDetail.OrderId:''));}}/>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>ชื่อไฟล์</th>
                                                    <th className='text-center'>ประเภทไฟล์</th>
                                                    <th className='text-center'>วันที่ / เวลา</th>
                                                    <th className='text-center'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {FileLsit.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-left'>{v.FileName}</td>
                                                        <td className='text-center'>{v.FileType}</td>
                                                        <td className='text-center'>{DateFormat(v.FileCreate,true)}</td>
                                                        <td className='text-end'>
                                                            <div className='btn-group btn-group-sm'>
                                                                <a href={RootURL()+v.FileURL} className='btn btn-info  text-white' target='_blank'>เปิดไฟล์</a>
                                                                <button type='button' className='btn btn-danger text-white' onClick={()=>{_DeleteFile(v);}}>ลบ</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <OrderCardStatusInfo ref={_OrderCardStatusInfo} callback={(val)=>{_UpdateOrderCardStatusInfo(val);}}/>
        <OrderCardEdit ref={_OrderCardEdit} callback={(val)=>{_EditCallBack(val);}}/>
        </>)
        
});

export default React.memo(OrderCardInfo)