import React,{forwardRef,useImperativeHandle,useEffect } from 'react';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
//import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const DataTable = forwardRef((props,ref) =>{
    const TableID = props.TableID;
    var _Config = {};
    let _columnDefs = [];
    if(props.ColumnDefs != undefined) _columnDefs = props.ColumnDefs;
    if(props.ajax === undefined){
        _Config = {
          processing: true,
          serverSide: false,
          orderMulti: false,
          searching: true,
          language: {
            processing: `<h2 class='text-center text-danger col-md-12 mt-1' style="background-color:#fff;">Loading..</h2>`,
            loadingRecords: "Loading.."
          },
          columnDefs: [
                { className: "text-center", targets: 'textcenter' },
                { className: "text-left", targets: 'textleft' },
                { className: "text-end", targets: 'textright' },
                { className: "text-end", targets: 'textend' },
                { targets: 'no-sort', orderable: false }
            ],
            ...props.Configs
          };
    }else{
        _Config = {
          processing: true,
          serverSide: true,
          filter: true,
          orderMulti: false,
          searching: true,
          responsive: true,
          ajax: props.ajax,
          language: {
            processing: `<h2 class='text-center text-danger col-md-12 mt-1' style="background-color:#fff;">Loading..</h2>`,
            loadingRecords: "Loading.."
          },
            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              return nRow;
            },
            columnDefs: [
                { className: "text-center", targets: 'textcenter' },
                { className: "text-left", targets: 'textleft' },
                { className: "text-end", targets: 'textright' },
                { className: "text-end", targets: 'textend' },
                { targets: 'no-sort', orderable: false }
            ],
            data: props.data,
            columns: props.Column,
            ...props.Configs
          };
    }
    // eslint-disable-next-line 
    useEffect(() => {
        if (!$.fn.DataTable.isDataTable('#'+TableID)) {
            $(document).ready(function () {
              $.fn.dataTable.ext.errMode = 'none';
              setTimeout(function () {
                $('#'+TableID).DataTable(_Config);
              }, 100);
            });
        }
        return ()=>{
            $('#'+TableID).DataTable().destroy();
        }
        // eslint-disable-next-line 
    }, []);


    useImperativeHandle(ref, () => ({
        load(){
            if(props.ajax === undefined){
              $('#'+TableID).DataTable().draw();
            }
            else
            $('#'+TableID).DataTable().ajax.reload();
        }
      }));
      
});

export default React.memo(DataTable)