import React from "react";
import '../Assets/CSS/Horapa.css';
import Moment from 'moment';

const Footer = props => {
// eslint-disable-next-line
    const {  title } = props;

    return (
        <>
            <footer className="footer mt-auto py-3 bg-footer">
                <div className="container ">
                    <div className="row">
                        <div className='col-6'><span className="fs-5">{Moment().format('DD/MM/YY HH:mm')}</span></div>
                        <div className='col-6 text-end'><span className="text-white fs-5">บริษัท โหระพาแคทเทอริ่ง จำกัด</span></div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;