import React,{useRef,useState,useEffect} from 'react';
import {DataTable,OrderCardInfo,OrderCardNew} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import {OrderStatus,OrderStatusList,OrderStatusLabel} from '../../Utils/Status'
import { createRoot } from 'react-dom/client';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Swal from 'sweetalert2';
import API from '../../API';

import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PackagePage =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _OrderCard = useRef();
    const _OrderCardNew = useRef();
    const [PackageGroupList,setPackageGroupList] = useState([]);

    useEffect(() => {
        (async () => {
            await _LoadPackageGroupList();
        })();

        return ()=>{ }
    }, []);
    const _LoadPackageGroupList = async()=>{
        API({method : "get",url:"/Package/Group/List"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageGroupList(res.data.Response);
        }).catch(res => {
            return;
        });
    }

    const _PackageInfo = (PackageID) =>{

    }

    const _OrderInfo = (OrderID)=>{
        _OrderCard.current?.Show(OrderID);
    }
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Package/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.PackageGroup = getValues('PackageGroup');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _PackageInfo(d)}>Info</button>
            {(GetRole() === 99 ? <button type='button' title='รายละเอียด' className='btn btn-danger text-white' onClick={() => _PackageDelete(d,r)}>ลบ</button>:'')}
        </div>
    )
    const _column = [
        { data: "PackageCode", name: "PackageCode", autoWidth: true },
        { data: "PackageName", name: "PackageName", autoWidth: true },
        { data: "PackageGroupName", name: "PackageGroupName", autoWidth: true },
        { data: "Description", name: "Description", autoWidth: true },
        {data: "Price", name: "Price", autoWidth: true,render(data){
            return NumberFormat(data);
        }},
        { data: "MinPeopleNum", name: "MinPeopleNum", autoWidth: true },
        { data: "Remark", name: "Remark", autoWidth: true },
        {data: "Status", name: "Status", autoWidth: true,render(data){
            switch(data){
                case 1 : return 'เปิด';
                case 0 : return 'ปิด';
                default: return '';
            }
        }},
        {
            data: "PackageId", name: "PackageId", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }
    const _CreatePackage = ()=>{

    }
    const _PackageDelete =async(PackageID,PackageDetail)=>{

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการลบรายการ หรือไม่ ?',
            text : (PackageDetail != undefined ? PackageDetail.PackageCode + ' ['+PackageDetail.PackageNAme+']':"1"),
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Package/Profile/"+PackageID}).then(res=>{
                if (res.status !== 200) {
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.data.Message,
                    });
                    return;
                }
    
                if (!res.data.Status) {
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message,
                    });
                    return;
                }
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                ref.current?.load();
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                })
            });
        });
    }

    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                            <div className='row d-flex justify-content-end'>
                                <div className='col-2 col-lg-2 mt-2 text-end'>
                                    <select className='form-control text-center' {...register("PackageGroup", { required: false })} defaultValue={0}>
                                        <option value={0} selected>ทั้งหมด</option>
                                        {PackageGroupList.map((v,i)=>{
                                            return <option key={i} value={v.PackageGroupId}>{v.PackageGroupName}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-2 col-lg-2 text-end'>
                                    <div className='btn btn-group'>
                                        <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>Search</button>
                                        <button type='button'  className='btn btn-primary text-white' onClick={()=>{_CreatePackage();}}>เพิ่ม Package</button>
                                    </div>
                                </div>
                            </div>

                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="HomeOrderView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">รหัสแพ็คเกจ</th>
                                                    <th className="text-center textleft">ชื่อแพ็คเกจ</th>
                                                    <th className="text-center textcenter">ผลิตภัณฑ์ </th>
                                                    <th className="text-center textleft">รายละเอียด</th>
                                                    <th className="text-center textright">ราคา</th>
                                                    <th className="text-center textcenter">จำนวนหัวขั้นต่ำ</th>
                                                    <th className="text-center textcenter">หมายเหตุ</th>
                                                    <th className="text-center textcenter">สถานะ</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"HomeOrderView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OrderCardInfo ref={_OrderCard} callback={()=>{_UpdateTable();}}/>
            <OrderCardNew ref={_OrderCardNew} aftercreate={(d)=>{console.log(d);_OrderInfo(d);}}/>
        </React.Fragment>
        </>
    )
}

export default React.memo(PackagePage)
