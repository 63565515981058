import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import Swal from 'sweetalert2';
import API from '../../API';

const FileUpload = forwardRef((props,ref) =>{
    const _RefFile = useRef();
    const [_FileType,setFileType] = useState(0);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({

      }));
    const changeHandler = (e) =>{
        if (e.target.files.length === 0) return
        if(props.OrderID === undefined || props.OrderID===null){
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบข้อมูล Order'
            });
            return;
        }
        const formData = new FormData();
        formData.append('File', e.target.files[0]);
        formData.append('FileType', Number(_FileType));
        e.target.value = null;
        API({ method: "post", url: "/File/Upload/"+props.OrderID, data: formData, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if (res.status != 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'อัพโหลดไฟล์ไม่สำเร็จ'
                });
                return;
            }
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: 'อัพโหลดไฟล์ไม่สำเร็จ',
                    text: res.data.Message
                });
                return;
            }
            setFileType(0);
            if(props.callback != undefined){
                try{
                    props.callback(res.data.Response);
                }catch(e){}
            }
        });
    }
    return (
        <>
        <div className='row'>
            <div className='col-lg-8 text-end'>
                <select className='form-control' defaultValue={_FileType} onChange={(e)=>{setFileType(e.target.value);}}>
                    <option value={0} selected={true}>ไม่ระบุ</option>
                    <option value={1}>ใบเสนอราคา</option>
                    <option value={2}>สลิปธนาคาร</option>
                    <option value={3}>แผนที่</option>
                </select>
            </div>
            <div className='col-lg-4 text-left'>
                <input type="file" style={{'display':'none'}} ref={_RefFile} onChange={changeHandler} />
                <button className='btn btn-success btn-sm' onClick={()=>{_RefFile.current.click();}}>เพิ่มไฟล์</button>
            </div>
        </div>
        </>)
        
});

export default React.memo(FileUpload)