import React,{useImperativeHandle,forwardRef,useEffect, useState } from 'react';
import {Modal} from "reactstrap";

import Swal from 'sweetalert2';
import API from '../../API';

const UserChagePassword = forwardRef((props,ref) =>{
    const [ModalState,setModalState] = useState(false);
    const [_Password,setPassword] = useState();
    const [_PasswordNew,setPasswordNew] = useState();
    const [_PasswordConfirm,setPasswordConfirm] = useState();
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    useImperativeHandle(ref, () => ({
        Show(){
            setModalState(true);
        }
    }));

    const _ChangePassword = async()=>{

        if(_Password === undefined || _Password === null || _PasswordNew === undefined || _PasswordNew === null || _PasswordConfirm === undefined || _PasswordConfirm === null || _PasswordNew != _PasswordConfirm){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบรหัสผ่าน'
            });
            return;
        }
        const _reqObj = {
            Password:_Password,
            NewPassword:_PasswordNew
        }
        
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการเปลี่ยนรหัสผ่าน หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'เปลี่ยนรหัส',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/User/ChangePassword',data:_reqObj,headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                }).then(()=>{
                    setModalState(false);
                });
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-sm'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">เปลี่ยนรหัสผ่าน :: {sessionStorage.getItem('Name')}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>

            <div className='modal-body'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="mb-3">
                            <label className="form-label">รหัสผ่าน</label>
                            <input type="password" className="form-control" placeholder="รหัสผ่าน" autoComplete='off' defaultValue={_Password} onChange={(e)=>{setPassword(e.target.value);}} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'><hr/></div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <div className="mb-3">
                            <label className="form-label">รหัสผ่านใหม่</label>
                            <input type="password" className="form-control" placeholder="รหัสผ่าน" autoComplete='off' defaultValue={_PasswordNew} onChange={(e)=>{setPasswordNew(e.target.value);}} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="mb-3">
                            <label className="form-label">ยืนยันรหัสผ่านใหม่</label>
                            <input type="password" className="form-control" placeholder="รหัสผ่าน" autoComplete='off'defaultValue={_PasswordConfirm} onChange={(e)=>{setPasswordConfirm(e.target.value);}} />
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-12 text-center'>
                        <button type="button" className='btn btn-success btn-lg' onClick={()=>{_ChangePassword();}}>เปลี่ยนรหัสผ่าน</button>
                    </div>
                </div>
            </div>
        </Modal>
        </>)
        
});

export default React.memo(UserChagePassword)