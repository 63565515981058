import React from 'react'
import {Helmet} from "react-helmet"
import Router from '../Router'
import Content from './Content'

class Layout extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            SiteTitle:''
        }
    }
    componentDidMount() {

    }
    componentDidUpdate() {

    }
    componentWillUnmount() {

    }
    render() {
        const {SiteTitle} = this.state;

        return (
            <>
            <Helmet>
                <title>{SiteTitle}</title>
                <meta name="description" content={SiteTitle} />
            </Helmet>
            <React.Fragment>
                <Content>
                    <Router/>
                </Content>
            </React.Fragment>
            </>
        )
    }
}

export default Layout;
