import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import Moment from 'moment';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class HomeChart extends React.Component {
    render(){
        const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light1", // "light1", "dark1", "dark2"
			title:{
				text: "ยอดขายเป้าหมาย "+Moment().format('DD/MM/YY')
			},
			data: [{
				type: "pie",
				indexLabel: "{label}: {y}%",
				startAngle: -90,
				dataPoints: [
					{ y: 40, label: "รอติดตาม" },
					{ y: 25, label: "เสนอราคา" },
					{ y: 20, label: "ชำระมัดจำ" },
					{ y: 15, label: "Hold" }
				]
			}]
		}

        return (
            <div>
                <CanvasJSChart options={options} />
            </div>
        )
    }
}
export default HomeChart;