import React,{useRef,useState,useEffect} from 'react';
import '../../Assets/CSS/Horapa.css';
import {DateFormat, DateFormatJSON, NumberFormat} from '../../Utils/Function';
import API from '../../API';
import Swal from 'sweetalert2';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PageReportDeposit =()=> {
    const [_DateList,setDateList] = useState([]);
    const [_Date,setDate] = useState(new Date());
    const [_ReqDate,setReqDate] = useState(new Date());
    const [_ResList,setResList] = useState([]);
    useEffect(() => {
        (async () => {
        })();
        return ()=>{ 
        }
    }, []);
    const _AddDate = ()=>{
        let _tmpAR = [... _DateList];
        let _Error = false;
        _tmpAR.find((v,i)=>{
            if(v === _Date) _Error = true;
        });
        if(_Error){
            Swal.fire({
                icon: 'error',
                title: 'วันที่ซ้ำ'
            });
            return;
        }
        _tmpAR.push(_Date);
        setDateList(_tmpAR);
    }
    const _Search = ()=>{
        let _Obj = [];
        _DateList.find((v,i)=>{
            _Obj.push(DateFormatJSON(v,false));
        });
        const _reqObj = {
            Request : DateFormatJSON(_ReqDate,false),
            Date : _Obj
        }
        Swal.isLoading();

        API({method : "post",url:"/Report/Date/Deposit/Search",data:_reqObj}).then(res=>{
            console.log(res);
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return
            }

            setResList(res.data.Response);
            Swal.close();
        }).catch(res => {
            return;
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='row'>
                                    <div className='col-lg-7'><h5 className='fw-bold'>ข้อมูล รายงานนัดโอน</h5></div>
                                    <div className='col-lg-2 text-end'>
                                        <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={_ReqDate} onChange={(date)=>{setReqDate(date)}} selectsStart startDate={_ReqDate}/>
                                    </div>
                                    <div className='col-lg-2 text-end'>
                                        <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={_Date} onChange={(date)=>{setDate(date)}} selectsStart startDate={_Date}/>
                                    </div>
                                    <div className='col-lg-1'>
                                        <div className='btn-group btn-group-sm mt-1'>
                                            <button type="button" className='btn btn-success btn-sm' onClick={()=>{_AddDate();}}>+ เพิ่ม</button>
                                            <button type="button" className='btn btn-danger btn-sm' onClick={()=>{_Search();}}>Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <table  className="table-responsive">
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>วันที่</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {_DateList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td>{DateFormat(v,false)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>วันที่</th>
                                                    <th className='text-center'>จำนวนรายการ</th>
                                                    <th className='text-center'>ยอดรวม</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {_ResList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-center'>{DateFormat(v.Date)}</td>
                                                        <td className='text-center'>{NumberFormat(v.Count)}</td>
                                                        <td className='text-end'>{NumberFormat(v.Amount)}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageReportDeposit)
