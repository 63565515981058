import React,{useRef,useState} from 'react';
import { HomeChart } from '../../Components/Chart';
import {DataTable,OrderCardInfo,OrderCardNew} from '../../Components'
import { FaRegUser,FaInfo  } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import {OrderStatus,OrderStatusList,OrderStatusLabel} from '../../Utils/Status'
import { createRoot } from 'react-dom/client';
import DatePicker from "react-datepicker";
import moment from 'moment';
import {WidgetAppointment,WidgetDayReport} from '../../Widget';
import Swal from 'sweetalert2';
import API from '../../API';

import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const Home =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const [StartDate,setStartDate] = useState(moment().add(-14, 'day').toDate());
    const [EndDate,setEndDate] = useState(moment().toDate())
    const [_SearchTable,setSearchTable] = useState();
    const [_OrderTable,setOrderTable] = useState()
    const [_ColumnsTable,setColumnsTable] = useState();
    const ref = useRef();
    const _OrderCard = useRef();
    const _OrderCardNew = useRef();
    const _CustomerInfo = ()=>{
        RedirectTo('/Customer');
    }
    const _OrderInfo = (OrderID)=>{
        _OrderCard.current?.Show(OrderID);
    }
    const _configs = {
        "order": [[2, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Order/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            if(d.search.value != undefined){
                setSearchTable(d.search.value);
            }
            if(d.order != undefined){
                setOrderTable(d.order);
            }
            if(d.columns != undefined){
                setColumnsTable(d.columns);
            }
            d.Status = getValues('OrderStatus');
            d.DateType = getValues("DateType");
            d.StartDate = DateFormat((getValues('StartDate') === undefined || getValues('StartDate') === null ? moment().add(-7, 'day').toDate() : getValues('StartDate')));
            d.EndDate = DateFormat((getValues('EndDate') === undefined || getValues('EndDate') === null ?moment().toDate() : getValues('EndDate') ));
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _OrderInfo(d)}>Info</button>
            {(GetRole() === 99 ? <button type='button' title='รายละเอียด' className='btn btn-danger text-white' onClick={() => _OrderDelete(d,r)}>ลบ</button>:'')}
        </div>
    )
    const _column = [
        { data: "CustomerName", name: "CustomerName", autoWidth: true },
        {data: "CustomerMobile", name: "CustomerMobile", autoWidth: true,render(data){
            return MobileFormat(data);
        }},
        {data: "QuotationDate", name: "QuotationDate", autoWidth: true,render(data){
            return DateFormat(data);
        }},
        {data: "EventDate", name: "EventDate", autoWidth: true,render(data){
            return DateFormat(data);
        }},
        {data: "DepositDate", name: "DepositDate", autoWidth: true,render(data){
            if(data != null) return DateFormat(data); else return '-';
        }},
        { data: "PackageGroupName", name: "PackageGroupName", autoWidth: true },
        { data: "PackageName", name: "PackageName", autoWidth: true },
        {data: "Amount", name: "Amount", autoWidth: true,render(data){
            return NumberFormat(data);
        }},
        {data: "StatusStateNum",name:"StatusStateNum",autoWidth:true},
        {data: "Status", name: "Status", autoWidth: true,render(data){
            return OrderStatusLabel(data);
        }},
        { data: "UserName", name: "UserName", autoWidth: true },
        {data: "LastUpdate", name: "LastUpdate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "OrderId", name: "OrderID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
        {targets: [9],orderData: [9, 3]}
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }
    const _CreateOrder = ()=>{
        _OrderCardNew.current?.Show();
    }
    const _ExportExcel = async() =>{
        const _reqObj = {
            search : _SearchTable,
            columns: _ColumnsTable,
            order: _OrderTable,
            Status:getValues('OrderStatus'),
            DateType:getValues("DateType"),
            StartDate:DateFormat((getValues('StartDate') === undefined || getValues('StartDate') === null ? moment().add(-7, 'day').toDate() : getValues('StartDate'))),
            EndDate:DateFormat((getValues('EndDate') === undefined || getValues('EndDate') === null ?moment().toDate() : getValues('EndDate') ))
        };
        API({ method: "post", url: "/Order/Export", data: _reqObj,responseType:'blob', headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            let _fileName = res.request.getResponseHeader('Content-Disposition').split(';')[1].split('=')[1];
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res.data);
            link.download = _fileName;
            link.click();

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            })
        });;
    }
    const _OrderDelete =async(OrderID,OrderDetail)=>{

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการลบรายการ หรือไม่ ?',
            text : (OrderDetail != undefined ? OrderDetail.CustomerName + ' ['+MobileFormat(OrderDetail.CustomerMobile)+'] :: '+DateThai(OrderDetail.EventDate):"1"),
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Order/Detail/"+OrderID}).then(res=>{
                if (res.status !== 200) {
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.data.Message,
                    });
                    return;
                }
    
                if (!res.data.Status) {
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message,
                    });
                    return;
                }
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                ref.current?.load();
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                })
            });
        });
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-6'>
                        <WidgetAppointment/>

                    </div>
                    <div className='col-lg-6'>
                        <WidgetDayReport />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                            <div className='row d-flex justify-content-end'>
                                <div className='col-2 col-lg-2 mt-2'>
                                    <select className='form-control text-center' {...register("OrderStatus", { required: false })} defaultValue={0}>
                                        <option value={0} selected>ทั้งหมด</option>
                                        {OrderStatusList.map((v,i)=>{
                                            return <option key={i} value={v}>{OrderStatus(v)}</option>
                                        })}
                                    </select>
                                </div>

                                <div className='col-1 col-lg-1 mt-2'>
                                    <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={StartDate} onChange={(date)=>{setStartDate(date);setValue('StartDate',date);}} selectsStart startDate={StartDate} endDate={EndDate}/>
                                </div>
                                <div className='col-1 col-lg-1 mt-2'>
                                    <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={EndDate} onChange={(date)=>{setEndDate(date);setValue('EndDate',date);}} selectsEnd startDate={StartDate} endDate={EndDate} />
                                </div>
                                <div className='col-1 col-lg-1 mt-2'>
                                    <select className='form-control text-center' {...register("DateType", { required: false })}  defaultValue={2}>
                                        <option value={1}>วันที่จัดงาน</option>
                                        <option value={2} selected>วันที่เสนอราคา</option>
                                        <option value={3}>วันที่ชำระมัดจำ</option>
                                        <option value={4}>วันที่แก้ไข</option>
                                    </select>
                                </div>
                                <div className='col-3 col-lg-3 text-end'>
                                    <div className='btn btn-group'>
                                        <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>Search</button>
                                        <button type='button'  className='btn btn-success text-white' onClick={()=>{_ExportExcel();}}>Export Excel</button>
                                        <button type='button'  className='btn btn-primary text-white' onClick={()=>{_CreateOrder();}}>สร้างรายการ</button>
                                    </div>
                                </div>
                            </div>

                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="HomeOrderView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">ลูกค้า</th>
                                                    <th className="text-center textcenter">เบอร์โทร</th>
                                                    <th className="text-center textcenter">วันที่เสนอราคา</th>
                                                    <th className="text-center textcenter">วันที่จัดงาน</th>
                                                    <th className="text-center textcenter">วันที่ชำระมัดจำ</th>
                                                    <th className="text-center textcenter">ประเภท</th>
                                                    <th className="text-center textcenter">แพ็คเก็จ</th>
                                                    <th className="text-center textright">ยอด</th>
                                                    <th className="text-center textcenter">ตาม(ครั้ง)</th>
                                                    <th className="text-center textcenter">สถานะ</th>
                                                    <th className="text-center textcenter">พนักงาน</th>
                                                    <th className="text-center textcenter">วันที่แก้ไข</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"HomeOrderView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OrderCardInfo ref={_OrderCard} callback={()=>{_UpdateTable();}}/>
            <OrderCardNew ref={_OrderCardNew} aftercreate={(d)=>{console.log(d);_OrderInfo(d);}}/>
        </React.Fragment>
        </>
    )
}

export default React.memo(Home)
