import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {PageLogin,PageHome,PageUserOnline,PagePackage,PageTargetDayList,PageTargetMonthList} from './Pages';
import {PageReportFollow,PageReportDeposit,PageReportAppointment} from './Pages/Report';
import {IsLogged,GetRole} from './Utils/Function';

const Router =() =>{
    if(IsLogged()){
        return (
            <Routes>
                <Route path="/Home" element={<PageHome />} />
                <Route path="*" element={<PageHome />} />
                {(GetRole() === 99 ? <Route path="/User/Online" element={<PageUserOnline />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Package" element={<PagePackage />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Setting/Target/Day" element={<PageTargetDayList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Setting/Target/Month" element={<PageTargetMonthList />} /> :"")}
                <Route path="/Report/Follow" element={<PageReportFollow />} />
                <Route path="/Report/Deposit" element={<PageReportDeposit />} />
                <Route path="/Report/Appointment" element={<PageReportAppointment />} />

            </Routes>
        )
    }else{
        return (
            <Routes>
                <Route path="*" element={<PageLogin />} />
            </Routes>
        )
    }
    
}

export default React.memo(Router)